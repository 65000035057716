import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";
// apenas valida se esta logado
function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    next({ name: "login" });
  }
}

// apenas valida se tem permissao
async function guardPermissao(to, from, next) {
  const permissoes = await getPermissao();
  const funcionalidadeNecessaria = to.meta.funcionalidadeId;
  if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
    next({ name: "home" });
  } else {
    next();
  }
}

// combinacao permissao + verificacao logado
export function guardPermissaoRoute(to, from, next) {
  guardMyroute(to, from, (guardResultado) => {
    if (guardResultado !== undefined) {
      next(guardResultado);
    } else {
      guardPermissao(to, from, next);
    }
  });
}