<template>
  <HeaderHome />

  <body class="inicial">
    <main>
      <section>
        <div class="titulo">
          <div class="container margem alinha-centro">
            <h2>Selecione o módulo</h2>
          </div>
        </div>
        <div class="margem container">
          <div v-if="dataLoaded" class="grid-4">
            <a v-for="modulo in menus" :key="modulo.id" :href="modulo.URL" class="card bloco">
              <figure>
                <img :src="getImageSrc(modulo.caminho_img)" />
                <figcaption class="margem abs">{{ modulo.nome }}</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </section>
    </main>
  </body>
</template>

<script>
import moduloService from "@/services/moduloService";
import HeaderHome from "@/components/HeaderHome.vue";
import { ref } from "vue";

export default {
  name: "HomeView",
  components: {
    HeaderHome,
  },
  data() {
    return {
      dataLoaded: false,
    };
  },

  methods: {
    getImageSrc(imagePath) {
      try {
        return require(`../assets/img/${imagePath}`);
      } catch (e) {
        console.error("Image not found:", imagePath);
        return "";
      }
    },
  },

  async created() {
    this.menus = await moduloService.buscarModulos();
    this.dataLoaded = true;
    console.log(this.menus);
  },

  setup() {
    let modulosList = ref(new Array());
    var menus = null;
    moduloService.buscarModulos().then((resp) => {
      console.log(menus);
      menus = resp;
    });

    const funcionalidades = ref([]);
    const showIndicadores = ref(false);

    return {
      menus,
      modulosList,
      funcionalidades,
      showIndicadores,
    };
  },
};
</script>

<style scoped>
.card img {
  width: 100%;
  height: 250px;
}
</style>
