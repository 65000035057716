import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import { sso } from "roboflex-thalamus-sso-lib"
import EsqueceuSenhaView from "@/views/Senha/EsqueceuSenhaView.vue";
import ValidarTokenView from "@/views/Senha/ValidarTokenView.vue";
import { guardPermissaoRoute } from "@/services/funcionalidadeService";


function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}
// http://localhost.thalamus.ind.br:8080/#/login
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/esqueci-minha-senha",
    name: "EsqueciMinhaSenha",
    component: EsqueceuSenhaView,
  },
  {
    path: "/validar-token",
    name: "ValidarToken",
    component: ValidarTokenView,
  },
  {
    path: "/pagina",
    name: "pagina",
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 56 },
  },
];
//artur.dias@roboflex.com.br

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
